input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;

  @apply bg-white;
  @apply border rounded-full border-coolGrey-300;
}

input[type="radio"]:checked {
  @apply border-blue-300 border-6;
}

input[type="radio"]:hover:not(:checked) {
  @apply bg-navy-300 bg-opacity-4;
  @apply border rounded-full border-navy-100;
}

input[type="radio"]:hover {
  cursor: pointer;
}
