@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    /* font */
    @apply font-secondary text-base font-normal;
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
  }

  body.dark {
    /* font */
    /* color */
    @apply bg-navy-300;
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
  }

  /*
  ========================================================
  # HEADINGS
  ========================================================
  */

  .display {
    /* font */
    @apply font-primary font-normal;
    /* color */
    @apply text-navy-300;
    /* margin and padding */
    @apply text-display tracking-base leading-display;
    /* hover */
    /* active */
    /* disabled */
  }

  .display.dark {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
  }

  h1 {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
    @apply text-h1 uppercase tracking-base leading-h1 text-blue-300;
  }

  h1.dark {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
  }

  h2 {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
    @apply font-primary text-h2 tracking-base leading-h2 text-navy-300;
  }

  h2.dark {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
  }

  h3 {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
    @apply font-primary text-h3 tracking-base leading-h3;
  }

  h3.dark {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
  }

  h4 {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
    @apply text-h4 tracking-base leading-h4 font-bold;
  }

  h4.dark {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
  }

  h5 {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
    @apply text-h5 tracking-base leading-h5;
  }

  h5.dark {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
  }

  h6 {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
    @apply text-h6 tracking-base leading-h6;
  }

  h6.dark {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
  }

  .h7 {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
    @apply text-h7 normal-case tracking-base leading-h7;
  }

  .h7.dark {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
  }

  /*
  ========================================================
  # BODY CONTENT
  ========================================================
  */

  .content-large {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
    @apply font-light text-h1 text-black-300 leading-h1;
  }

  .content-base {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
    @apply font-light text-base text-black-300 leading-h6;
  }

  .content-small {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
    @apply font-light text-h7 text-black-300 leading-h7;
  }

  .content-xs {
    /* font */
    /* color */
    /* margin and padding */
    /* hover */
    /* active */
    /* disabled */
    @apply font-semibold text-h8 text-black-300 leading-h8;
  }

  /*
  ========================================================
  # INPUTS
  ========================================================
  */

  input:not(:placeholder-shown):not(:focus).placeholder-not-shown\:bg-lightGrey-100 {
    @apply bg-lightGrey-100 bg-opacity-72;
  }

  /*
  ========================================================
  # TABLES
  ========================================================
  */

  table.table-rounded {
    @apply rounded;
  }

  table.table-rounded thead th:first-child {
    @apply rounded-tl;
  }

  table.table-rounded thead th:last-child {
    @apply rounded-tr;
  }

  table.table-rounded tbody tr:last-child td:first-child {
    @apply rounded-bl;
  }

  table.table-rounded tbody tr:last-child td:last-child {
    @apply rounded-br;
  }

  table:not(.compact) tbody tr td:first-child {
    @apply pl-10;
  }

  table:not(.compact) tbody tr td:last-child {
    @apply pr-10;
  }
}
