.rc-slider {
  @apply p-0 flex items-center;
}

.rc-slider-handle {
  @apply bg-blue-300 border-0 opacity-100 m-0;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  @apply border-0 shadow-none;
}

.rc-slider-track {
  @apply h-1 bg-blue-200 opacity-100;
}

.rc-slider-rail {
  @apply h-1 bg-coolGrey-200 opacity-100;
}

.rc-tooltip {
  @apply text-base text-black-300 absolute block visible opacity-100;

  z-index: 1070;
}

.rc-tooltip-inner {
  @apply text-left;

  text-decoration: none;
}

.rc-slider-dot {
  @apply invisible;
}
